<template>
    <Layout>
        
        <div class="clearfix"></div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions">
                                        </b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table :items="otpData" :fields="fields" responsive="sm" :busy="isBusy"
                                :per-page="perPage" show-empty>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                    </div>
                                </template>
                                <template #cell(actions)="row">
                                    <button class="action-btn" @click="deleteOtp(row.item)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                   
                                </template>
                               
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                                        </b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { otp } from "@/config/api/otp";

export default {
    page: {
        title: "Advanced Table",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout },
    data() {
        return {
            otpData: [],
            selectedUser: null,
            isBusy: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortDesc: false,
            fields: [
                { key: "phone_number", sortable: false },
                { key: "otp", sortable: false },
                { key: "no_of_times", sortable: false },
                { key: "actions", sortable: false },
            ],
        };
    },
    computed: {
        rows() {
            return this.totalRows;
        },
    },
    mounted() { },
    created() {
        this.getAllOtps();
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getAllOtps() {
            this.isBusy = true;
            const api = otp.get;
            api.params = {
                page: this.currentPage,
            };
            this.generateAPI(api)
                .then((res) => {
                    this.otpData = res.data.data;
                    this.totalRows = res.data.count;
                    this.isBusy = false;
                })
                .catch((err) => {
                    console.error(err.response.data);
                });
        },
        
        deleteOtp(data) {
            this.$bvModal
                .msgBoxConfirm(`Please confirm that you want to delete ${data.phone_number}.`, {
                    title: "Please Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        const api = otp.delete;
                        api.id = data._id;
                        this.generateAPI(api)
                            .then((res) => {
                                console.log(res.data);
                                this.$bvToast.toast("OTP deleted successfully!", {
                                    title: "Delete",
                                    variant: "success",
                                    solid: true,
                                });
                                this.getAllOtps();
                            })
                            .catch((err) => {
                                this.$bvToast.toast("OTP is not deleted!", {
                                    title: "Delete",
                                    variant: "danger",
                                    solid: true,
                                });
                                console.error(err.response.data);
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // An error occurred
                });
        },

        updatePage(val) {
            this.currentPage = val;
            this.getAllOtps();
            // this.getAllCampigns(this.currentPage);
            // this.perPage = val;
        },
    },
};
</script>
